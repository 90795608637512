svg { width: auto; height: auto; }
/*g { animation: rot 5s linear infinite; }*/

.svg-container {
    display: inline-block;
    position: relative;
    width: 100%;
    vertical-align: middle;
    overflow: hidden;
}

.svg-content {
    display: inline-block;
}

line.bottom{
    stroke: #D2DAE2;
}

line.top{
    stroke: #0FBAF8;
}

line.animate{
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    animation: rot2 5s linear reverse;
}

path.top{
    stroke: #0FBAF8;
}

path.bottom{
    stroke: #D2DAE2;
}

path.animate{
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    animation: rot2 10s linear reverse;
}

.parent {
    position: relative;
    display: flex;
    max-width: 108px;
}

svg.pos{
    position: relative;
}

div.frame{
    background-color: #F1F2F6;
    padding-top: 24px;
    padding-bottom: 32px;
    margin-top: 32px;
    margin-bottom: 48px;
    justify-content: center;
    /*max-width: 850px;*/
}

div.inner-frame{
}

p.ring-widget-value {
    position: absolute;
    align-self: center;
    font-weight: 900;
    font-style: italic;
    font-size: 20px;
}

p.ring-widget-title {
    position: absolute;
    align-self: center;
}

@keyframes rot2 {
    to{
        stroke-dashoffset: 1000;
    }
}