/* SVG loader */
.loader-wrapper{
    /*background:#333;*/
    height: 100px;
}

.loader {
    margin: 150px auto;
    width: 100px;
    height:100px;
}

.circular-loader {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    height: 100%;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    margin: auto;
}

.loader-path {
    stroke-dasharray: 150,200;
    stroke-dashoffset: -10;
    -webkit-animation: dash 1.5s ease-in-out infinite, 6s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite, 6s ease-in-out infinite;
    stroke-linecap: round;
}
/* konec SVG loaderja */

@keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-webkit-keyframes dash {
    0% {
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -124;
    }
}
@keyframes dash {
    0% {
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -124;
    }
}